@font-face {
  font-family: 'Quicksand Bold';
  src: url(./Quicksand-Bold.ttf);
}

@font-face {
  font-family: 'Quicksand Light';
  src: url(./Quicksand-Light.ttf);
}

@font-face {
  font-family: 'Quicksand Medium';
  src: url(./Quicksand-Medium.ttf);
}

@font-face {
  font-family: 'Quicksand Regular';
  src: url(./Quicksand-Regular.ttf);
}

@font-face {
  font-family: 'Quicksand Semibold';
  src: url(./Quicksand-SemiBold.ttf);
}

* {
  outline: none !important;
}
