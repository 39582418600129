@font-face {
    font-family: "IconsVendi";
    src: url("./IconsVendi.eot?55394faf3072aeb350a7375bf9530ea3#iefix") format("embedded-opentype"),
url("./IconsVendi.woff2?55394faf3072aeb350a7375bf9530ea3") format("woff2"),
url("./IconsVendi.woff?55394faf3072aeb350a7375bf9530ea3") format("woff");
}

i[class^="icon-"]:before, i[class*=" icon-"]:before {
    font-family: IconsVendi !important;
    font-style: normal;
    font-weight: normal !important;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-arrow-left:before {
    content: "\f101";
}
.icon-arrow-right:before {
    content: "\f102";
}
.icon-favorited:before {
    content: "\f103";
}
.icon-share:before {
    content: "\f104";
}
.icon-unfavorite:before {
    content: "\f105";
}
.icon-video-camera:before {
    content: "\f106";
}
